<template>
  <div class="settings">
    <h2>设置</h2>
    <el-form ref="form" :model="config" label-width="120px">
      <el-form-item label="卡密">
        <el-input v-model="config.card" placeholder="请输入卡密">
          <el-button @click="login" slot="append" :loading="loading" icon="el-icon-user">登录</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="下载路径">
        <el-input v-model="config.download.dir" placeholder="请输入下载路径">
        </el-input>
      </el-form-item>
      <h2>
        RPC
      </h2>
      <el-form-item label="推送地址">
        <el-input v-model="config.aria.rpc.url" placeholder="请输入RPC推送地址">
        </el-input>
      </el-form-item>
      <el-form-item label="TOKEN">
        <el-input v-model="config.aria.rpc.token" placeholder="请输入RPC推送TOKEN">
        </el-input>
      </el-form-item>
      <h2>
        IDM
      </h2>
      <el-form-item label="安装路径">
        <el-input v-model="config.idm.installPath" placeholder="请输入IDM安装路径(WEB版本中未被支持)">
          <el-button @click="autoGetIDMPath" slot="append">自动搜索</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="下载选项">
        <el-checkbox v-model="config.idm.silent" label="静默下载" border></el-checkbox>
        <el-checkbox v-model="config.idm.com" label="COM组件下载" border></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveConfig">保存</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>



<script>
export default {
  data() {
    return {
      loading: false,
      files:[],
      dialogVisible: false,
      config:{
        download:{
          dir: !localStorage.getItem('downloadDir')? 'download': localStorage.getItem('downloadDir')
        },
        aria:{
          rpc:{
            url: !localStorage.getItem('ariaRPC')? 'http://localhost:6800/jsonrpc': localStorage.getItem('ariaRPC'),
            token: !localStorage.getItem('ariaToken')? '': localStorage.getItem('ariaToken')
          },
        },
        idm:{
          installPath: '',
          silent: true,
          com: true
        },
        card: !localStorage.getItem('card')? '': localStorage.getItem('card')
      }
    }
  },
  mounted() {

  },
  methods: {
    getConfig(){
      const card = localStorage.getItem('card')
      const ariaRPC = localStorage.getItem('ariaRPC')
      const ariaToken = localStorage.getItem('ariaToken')
      const downloadDir = localStorage.getItem('downloadDir')
      this.config.card = card
      this.config.aria.rpc.url = ariaRPC
      this.config.aria.rpc.token = ariaToken
      this.config.download.dir = downloadDir
    },
    saveConfig() {
      localStorage.setItem('card', this.config.card)
      localStorage.setItem('ariaRPC', this.config.aria.rpc.url)
      localStorage.setItem('ariaToken', this.config.aria.rpc.token)
      localStorage.setItem('downloadDir', this.config.download.dir)
      this.$message({
        message: '保存成功',
        type: 'success'
      })
    },
    resetForm() {
      const card = localStorage.getItem('card')
      const ariaRPC = localStorage.getItem('ariaRPC')
      const ariaToken = localStorage.getItem('ariaToken')
      const downloadDir = localStorage.getItem('downloadDir')
      this.config.card = card
      this.config.aria.rpc.url = ariaRPC
      this.config.aria.rpc.token = ariaToken
      this.config.download.dir = downloadDir
      this.$message.success('重载成功')
    },
    autoGetIDMPath(){
      const path = this.$webview.getIdmPath()
      if(path){
        this.config.idm.installPath = path
        this.$webview.writeConfig('idm', this.config.idm)
      }else{
        //this.$message.warning('未找到IDM安装路径')
      }
    },
    handleClose(done) {
      done();
    },
    whatRule(){
      this.dialogVisible = true
    },
    login(){
      this.loading = true
      const card = this.config.card
      if(!card || card.length < 16){
        this.$message.warning('卡密错误')
        this.loading = false
        return
      }
      this.$webview.login(card).then(r =>{
        console.log(r)
        if(r.code != -1){
          this.$message.success(`登录成功,剩余次数:${r.data.hasNum}`)
          localStorage.setItem('card', card)
          this.config.card = card
        }else{
          this.$message.error(r.message)
          localStorage.removeItem('card')
        }
        this.loading = false
      })

    }
  }
}
</script>



<style scoped>

</style>